
'use client'

import { useTranslations } from 'next-intl'
import { LocaleLink } from '@/components/ui/LocaleLink'
import { PartnerLogo } from './ImageOptimizer'
import { motion, LazyMotion, domAnimation } from 'framer-motion' // Import LazyMotion and domAnimation
import { useState } from 'react'
import { Card, CardContent, CardTitle } from '@/components/ui/Card' // Import Card components
import { cn } from '@/lib/utils' // Import cn utility

export function Services() {
  const t = useTranslations('home')

  const businessServices = [
    {
      id: 'business-pro',
      name: t('services.businessServices.businessPro.title'),
      description: t('services.businessServices.businessPro.description'),
      icon: '💼',
    },
    {
      id: 'events',
      name: t('services.businessServices.events.title'),
      description: t('services.businessServices.events.description'),
      icon: '🎭',
    },
    {
      id: 'chauffeur',
      name: t('services.businessServices.chauffeur.title'),
      description: t('services.businessServices.chauffeur.description'),
      icon: '🚘',
    },
  ]

  const individualServices = [
    {
      id: 'standard',
      name: t('services.services.standard.title'),
      description: t('services.services.standard.description'),
      icon: '🚗',
    },
    {
      id: 'pro',
      name: t('services.services.pro.title'),
      description: t('services.services.pro.description'),
      icon: '🚙',
    },
    {
      id: 'airport',
      name: t('services.services.airport.title'),
      description: t('services.services.airport.description'),
      icon: '✈️',
    },
  ]

  // Partner logos with actual images
  const partners = [
    { name: 'Four Seasons', logo: '/images/partners/four-seasons.jpg' },
    { name: 'Ramada Plaza', logo: '/images/partners/ramada-plaza.jpg' },
    { name: 'Golden Tulip', logo: '/images/partners/golden-tulip.jpg' },
    { name: 'Total', logo: '/images/partners/total.jpg' },
    { name: 'Jumia', logo: '/images/partners/jumia.jpg' },
    { name: 'Goethe Institute', logo: '/images/partners/goethe.jpg' }
  ]

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.2
      }
    }
  }

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: { type: 'spring', stiffness: 100 }
    }
  }

  // State for tracking which card is being hovered
  const [hoveredCard, setHoveredCard] = useState<string | null>(null)

  return (
    // Wrap the component content with LazyMotion
    <LazyMotion features={domAnimation}>
      <div className="bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <motion.div
          className="mx-auto max-w-2xl lg:text-center"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true, margin: "-100px" }}
          transition={{ duration: 0.5 }}
        >
          <h2 className="text-base font-semibold leading-7 text-yellow-600">
            {t('services.subtitle')}
          </h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            {t('services.title')}
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-700">
            {t('services.description')}
          </p>
        </motion.div>

        {/* Business Services Section - Refactored */}
        <motion.div
          className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none"
          variants={containerVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, margin: "-100px" }}
        >
          <h3 className="text-2xl font-bold tracking-tight text-gray-900 mb-8">{t('services.businessServicesTitle')}</h3>
          {/* Replace dl with div for layout */}
          <div className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3"> {/* dl -> div */}
            {businessServices.map((service) => (
              <div key={service.id}> {/* Keep this wrapper */}
                <motion.div
                  variants={itemVariants}
                onMouseEnter={() => setHoveredCard(service.id)}
                onMouseLeave={() => setHoveredCard(null)}
                className="flex" // Ensure motion div takes up space
              >
                <Card className={cn(
                  'flex flex-col w-full transition-all duration-300',
                  hoveredCard === service.id ? 'shadow-lg transform scale-105 bg-white' : 'shadow-sm hover:shadow-md'
                )}>
                  <CardContent className="p-6 flex flex-col flex-auto"> {/* Use flex-auto */}
                    {/* Replace dt with div */}
                    <div className="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900"> {/* dt -> div */}
                      <motion.span
                        className="text-2xl"
                        animate={hoveredCard === service.id ? { scale: [1, 1.2, 1] } : {}}
                        transition={{ duration: 0.5, repeat: hoveredCard === service.id ? Infinity : 0, repeatDelay: 3 }}
                      >
                        {service.icon}
                      </motion.span>
                      {/* Use CardTitle or keep as is */}
                      {service.name}
                    </div> {/* /dt -> /div */}
                    {/* Replace dd with div */}
                    <div className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-700"> {/* dd -> div */}
                      <p className="flex-auto">{service.description}</p> {/* Use flex-auto */}
                      <p className="mt-6">
                        <LocaleLink
                          href={`/services#${service.id}`}
                          className="text-sm font-semibold leading-6 text-yellow-600 hover:text-yellow-500 transition-colors"
                        >
                          {t('services.learnMore')} <span aria-hidden="true">→</span>
                        </LocaleLink>
                      </p>
                    </div> {/* /dd -> /div */}
                  </CardContent>
                </Card>
                </motion.div>
              </div>
            ))}
          </div> {/* /dl -> /div */}
        </motion.div>

        {/* Individual Services Section - Refactored */}
        <motion.div
          className="mx-auto mt-20 max-w-2xl lg:max-w-none"
          variants={containerVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, margin: "-100px" }}
        >
          <h3 className="text-2xl font-bold tracking-tight text-gray-900 mb-8">{t('services.individualServicesTitle')}</h3>
          {/* Replace dl with div for layout */}
          <div className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3"> {/* dl -> div */}
            {individualServices.map((service) => (
              <div key={service.id}> {/* Keep this wrapper */}
                <motion.div
                  variants={itemVariants}
                onMouseEnter={() => setHoveredCard(service.id)}
                onMouseLeave={() => setHoveredCard(null)}
                className="flex" // Ensure motion div takes up space
              >
                <Card className={cn(
                  'flex flex-col w-full transition-all duration-300',
                  hoveredCard === service.id ? 'shadow-lg transform scale-105 bg-white' : 'shadow-sm hover:shadow-md'
                )}>
                   <CardContent className="p-6 flex flex-col flex-auto"> {/* Use flex-auto */}
                    {/* Replace dt with div */}
                    <div className="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900"> {/* dt -> div */}
                      <motion.span
                        className="text-2xl"
                        animate={hoveredCard === service.id ? { scale: [1, 1.2, 1] } : {}}
                        transition={{ duration: 0.5, repeat: hoveredCard === service.id ? Infinity : 0, repeatDelay: 3 }}
                      >
                        {service.icon}
                      </motion.span>
                      {service.name}
                    </div> {/* /dt -> /div */}
                    {/* Replace dd with div */}
                    <div className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-700"> {/* dd -> div */}
                      <p className="flex-auto">{service.description}</p> {/* Use flex-auto */}
                      <p className="mt-6">
                        <LocaleLink
                          href={`/services#${service.id}`}
                          className="text-sm font-semibold leading-6 text-yellow-600 hover:text-yellow-500 transition-colors"
                        >
                          {t('services.learnMore')} <span aria-hidden="true">→</span>
                        </LocaleLink>
                      </p>
                    </div> {/* /dd -> /div */}
                  </CardContent>
                </Card>
                </motion.div>
              </div>
            ))}
          </div> {/* /dl -> /div */}
        </motion.div>

        {/* Partners Section (Unchanged) */}
        <motion.div
          className="mx-auto mt-32 max-w-7xl"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true, margin: "-100px" }}
          transition={{ duration: 0.8 }}
        >
          <h3 className="text-2xl font-bold tracking-tight text-gray-900 text-center mb-12">
            {t('services.trustedCompanies')}
          </h3>
          <div className="mx-auto grid max-w-lg grid-cols-2 items-center gap-x-8 gap-y-12 sm:max-w-xl sm:grid-cols-3 sm:gap-x-10 sm:gap-y-14 lg:mx-0 lg:max-w-none lg:grid-cols-6">
            {partners.map((partner, index) => (
              <motion.div
                key={partner.name}
                className="col-span-1 flex justify-center"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1, duration: 0.5 }}
              >
                <motion.div
                  whileHover={{ scale: 1.1 }}
                  transition={{ type: 'spring', stiffness: 300 }}
                  className="bg-white p-3 rounded-lg shadow-sm hover:shadow-md transition-shadow duration-300 w-full max-w-[120px]"
                >
                  <PartnerLogo
                    name={partner.name}
                    src={partner.logo}
                    metadata={{
                      alt: `${partner.name} Logo - E-Taxi Partner`,
                      description: `${partner.name} - Official E-Taxi Tunisia Partner`
                    }}
                  />
                </motion.div>
              </motion.div>
            ))}
          </div>
          <motion.div
            className="mt-16 flex justify-center"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.5, duration: 0.5 }}
          >
            <div className="flex flex-wrap justify-center items-center gap-4 text-sm font-medium text-gray-500">
              <div className="flex items-center">
                <motion.span
                  className="font-semibold text-yellow-600 mr-1"
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  viewport={{ once: true }}
                  transition={{ delay: 0.7 }}
                >
                  30+
                </motion.span>
                {t('services.stats.corporateClients')}
              </div>
              <span className="hidden sm:inline mx-2">•</span>
              <div className="flex items-center">
                <motion.span
                  className="font-semibold text-yellow-600 mr-1"
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  viewport={{ once: true }}
                  transition={{ delay: 0.9 }}
                >
                  500+
                </motion.span>
                {t('services.stats.activeTaxis')}
              </div>
              <span className="hidden sm:inline mx-2">•</span>
              <div className="flex items-center">
                <motion.span
                  className="font-semibold text-yellow-600 mr-1"
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  viewport={{ once: true }}
                  transition={{ delay: 1.1 }}
                >
                  10,000+
                </motion.span>
                {t('services.stats.monthlyRides')}
              </div>
            </div>
          </motion.div>
          </motion.div>
        </div>
      </div>
    </LazyMotion>
  )
}
