'use client'

// import { useState, useEffect } from 'react'; // Commented out animation state
import Image from 'next/image';
import { LocaleLink } from '@/components/ui/LocaleLink';
import { useTranslations } from 'next-intl';
// Removed framer-motion imports: import { motion, LazyMotion, domAnimation } from 'framer-motion';
import { buttonVariants } from '@/components/ui/Button'; // Import buttonVariants
import { cn } from '@/lib/utils';

export function Hero() {
  const t = useTranslations('home');
  // const [taxiImage, setTaxiImage] = useState('/animations/taxi-idle.png'); // Commented out animation state

  // Removed unused animation variants

  /* // Commented out taxi animation variants and effect
  // Animation variants for the taxi
  const taxiFloatVariants = {
    float: {
      y: [0, -10, 0], // Float up and down
      transition: {
        duration: 3,
        repeat: Infinity,
        ease: "easeInOut"
      }
    }
  };

  // Effect to switch taxi image periodically
  useEffect(() => {
    const intervalId = setInterval(() => {
      setTaxiImage('/animations/taxi-busy.png');
      setTimeout(() => {
        setTaxiImage('/animations/taxi-idle.png');
      }, 500); // Show busy for 0.5 seconds
    }, 7000); // Switch every 7 seconds

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, []);
  */

  return (
    // Removed LazyMotion wrapper
    // Use Next/Image for background to allow priority loading
    <div className="relative isolate overflow-hidden">
      {/* Background Image using Next/Image */}
      <Image
        src="/images/slider1.webp"
        alt="" // Decorative background image
        fill
        sizes="100vw"
        style={{ objectFit: 'cover', zIndex: -1 }} // Cover the container, place behind content
        priority // Prioritize loading for LCP
      />
      {/* Added a semi-transparent overlay for text readability */}
      <div className="absolute inset-0 bg-black bg-opacity-50 z-0"></div>
      {/* Increased vertical padding for better spacing */}
      {/* Added relative positioning and z-index to ensure content is above overlay */}
      <div className="relative z-10 mx-auto max-w-7xl px-6 pb-24 pt-10 sm:pb-32 lg:flex lg:items-center lg:justify-between lg:px-8 lg:py-48">
          {/* Text Content Area - Removed motion animation from this container to improve LCP */}
          <div
            className="mx-auto max-w-2xl flex-shrink-0 lg:mx-0 lg:max-w-xl lg:pt-8" // Adjusted max-width if needed
            // Removed variants={containerVariants}, initial="hidden", animate="visible"
          >
          {/* Removed variants={itemVariants} from children */}
          <div className="mt-24 sm:mt-32 lg:mt-16">
            <div className="h-12 w-48" style={{ position: 'relative' }}>
              <Image
                src="/images/logo.svg"
                alt="E-Taxi Logo"
                fill
                sizes="(max-width: 768px) 100vw, 192px"
                style={{ objectFit: 'contain' }}
                priority
              />
            </div>
          </div> {/* Corrected closing tag */}
          {/* Removed variants={itemVariants} */}
          {/* Changed text color to white for better contrast */}
          <h1
            className="mt-10 text-4xl font-bold tracking-tight text-white sm:text-6xl"
          >
            {t('hero.title')}
          </h1> {/* Corrected closing tag - Although h1 doesn't need motion, ensure consistency if it was changed */}
          {/* Removed variants={itemVariants} */}
          {/* Changed text color to light gray for better contrast */}
          <p
            className="mt-6 text-lg leading-8 text-gray-300"
          >
            {t('hero.subtitle')}
          </p> {/* Corrected closing tag */}
          {/* Removed variants={itemVariants} */}
          {/* Changed text color for better contrast */}
          <div
            className="mt-4 flex items-center text-sm text-yellow-300 font-semibold"
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 mr-2">
              <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z" />
            </svg>
            {t('hero.b2bTagline')}
          </div> {/* Corrected closing tag */}
          {/* Removed variants={itemVariants} */}
          <div
            className="mt-10 flex flex-wrap items-center gap-x-6 gap-y-4"
          >
            {/* Refactored Booking CTA - Apply styles directly to LocaleLink */}
            <LocaleLink
              href="/booking"
              className={cn(
                buttonVariants({ variant: 'primary', size: 'default' }),
                'bg-yellow-500 hover:bg-yellow-600 text-black' // Adjusted primary style
              )}
            >
              {t('hero.cta')}
            </LocaleLink>
            {/* Refactored Business Solutions CTA - Apply styles directly to LocaleLink */}
            <LocaleLink
              href="/services#business-pro"
              className={cn(
                buttonVariants({ variant: 'secondary', size: 'default' })
                // Removed explicit conflicting styles: 'bg-gray-800 hover:bg-gray-700 text-white'
              )}
            >
              {t('hero.businessSolutions')}
            </LocaleLink>
            {/* Learn More Link (kept as text link) - Changed text color */}
            <LocaleLink
              href="/services"
              className="text-sm font-semibold leading-6 text-gray-100 hover:text-yellow-300 transition-colors"
            >
              {t('hero.learnMore')} <span aria-hidden="true">→</span>
            </LocaleLink>
          </div> {/* Corrected closing tag */}
        </div> {/* This closing tag corresponds to the main div */}

        {/* Commented out Taxi Animation Area */}
        {/*
        <motion.div
          className="mt-16 sm:mt-24 lg:mt-0 lg:ml-10 lg:flex-shrink-0 lg:flex-grow hidden lg:block" // Added hidden lg:block
          variants={itemVariants} // Reuse item variant for fade-in
          initial="hidden"
          animate="visible"
        >
          <motion.div
            variants={taxiFloatVariants}
            animate="float"
            className="relative mx-auto w-[300px] h-[200px] sm:w-[400px] sm:h-[267px] lg:w-[450px] lg:h-[300px]" // Responsive size
          >
            <Image
              src={taxiImage}
              alt="Animated E-Taxi"
              fill
              sizes="(max-width: 640px) 300px, (max-width: 1024px) 400px, 450px"
              style={{ objectFit: 'contain' }}
              priority // Load the initial image quickly
            />
          </motion.div>
        </motion.div>
        */}
        </div>
      </div>
    // Removed dangling </LazyMotion> tag
  );
}
