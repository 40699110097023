'use client'

'use client'

import { useTranslations } from 'next-intl'
import Image from 'next/image' // Import next/image
import { motion, LazyMotion, domAnimation } from 'framer-motion' // Import LazyMotion and domAnimation
import { StarIcon } from '@heroicons/react/20/solid'
import { useState } from 'react'
import { Card, CardContent } from '@/components/ui/Card' // Import Card components
import { Button } from '@/components/ui/Button' // Import Button component
import { cn } from '@/lib/utils' // Import cn utility

export function Testimonials() {
  const t = useTranslations('home')
  const [activeTestimonial, setActiveTestimonial] = useState(0)

  // Sample testimonials - in a real app, these would come from a CMS or API
  const testimonials = [
    {
      id: 1,
      name: 'Sarah M.',
      role: t('testimonials.roles.tourist'),
      content: t('testimonials.quotes.tourist'),
      rating: 5,
      image: 'https://randomuser.me/api/portraits/women/32.jpg',
    },
    {
      id: 2,
      name: 'Ahmed K.',
      role: t('testimonials.roles.businessman'),
      content: t('testimonials.quotes.businessman'),
      rating: 5,
      image: 'https://randomuser.me/api/portraits/men/54.jpg',
    },
    {
      id: 3,
      name: 'Emilie D.',
      role: t('testimonials.roles.resident'),
      content: t('testimonials.quotes.resident'),
      rating: 4,
      image: 'https://randomuser.me/api/portraits/women/68.jpg',
    },
  ]

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      }
    }
  }

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: { type: 'spring', stiffness: 100 }
    }
  }

  const handleNext = () => {
    setActiveTestimonial((prev) => (prev + 1) % testimonials.length)
  }

  const handlePrev = () => {
    setActiveTestimonial((prev) => (prev - 1 + testimonials.length) % testimonials.length)
  }

  return (
    // Wrap the component content with LazyMotion
    <LazyMotion features={domAnimation}>
      <section className="bg-gray-50 py-16 sm:py-20">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <motion.div
          className="mx-auto max-w-2xl lg:text-center"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
        >
          <h2 className="text-sm font-semibold leading-7 text-yellow-800">
            {t('testimonials.subtitle')}
          </h2>
          <p className="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
            {t('testimonials.title')}
          </p>
          <p className="mt-4 text-base leading-7 text-gray-700">
            {t('testimonials.description')}
          </p>
        </motion.div>

        <div className="mt-10 relative">
          {/* Refactor testimonial display to use Card */}
          <motion.div
            key={activeTestimonial} // Keep key for animation
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -100 }}
            transition={{ type: 'spring', stiffness: 100 }}
            className="mx-auto max-w-xl" // Remove card-like styles from motion.div
          >
            <Card className="overflow-hidden"> {/* Add overflow-hidden if needed */}
              <CardContent className="p-6 sm:p-8"> {/* Use CardContent for padding */}
                <div className="flex items-center gap-x-3 sm:gap-x-4">
                  <Image
                    className="h-10 w-10 rounded-full object-cover ring-2 ring-white"
                    src={testimonials[activeTestimonial].image}
                    alt={testimonials[activeTestimonial].name}
                    width={40} // Required for next/image
                    height={40} // Required for next/image
                  />
                  <div>
                    <div className="text-base font-semibold leading-6 text-gray-900">{testimonials[activeTestimonial].name}</div>
                    <div className="text-xs leading-5 text-gray-700">{testimonials[activeTestimonial].role}</div>
                    <div className="mt-1 flex">
                      {Array.from({ length: 5 }).map((_, i) => (
                        <StarIcon
                          key={i}
                          className={`h-3 w-3 ${
                            i < testimonials[activeTestimonial].rating ? 'text-yellow-500' : 'text-gray-300'
                          }`}
                          aria-hidden="true"
                        />
                      ))}
                    </div>
                  </div>
                </div>
                <figure className="mt-4">
                  <blockquote className="text-sm leading-6 text-gray-700">
                    <p>"{testimonials[activeTestimonial].content}"</p>
                  </blockquote>
                </figure>
              </CardContent>
            </Card>
          </motion.div>

          {/* Navigation buttons - Refactored */}
          <div className="mt-6 flex justify-center gap-x-4 items-center"> {/* Increased gap */}
             {/* Previous Button */}
             <Button
               variant="secondary"
               size="icon"
               className="rounded-full p-1.5 h-auto w-auto" // Adjust size/padding
               onClick={handlePrev}
               aria-label="Previous testimonial"
             >
               <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                 <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
               </svg>
             </Button>

             {/* Dot Indicators */}
             <div className="flex gap-x-3"> {/* Increased gap */}
               {testimonials.map((_, index) => (
                 <Button
                   key={index}
                   variant="ghost" // Use ghost or a custom variant for dots
                   size="icon" // Use icon size
                   className={cn(
                     "h-4 w-4 rounded-full p-0", // Increased size
                     index === activeTestimonial ? 'bg-yellow-500' : 'bg-gray-300 hover:bg-gray-400'
                   )}
                   onClick={() => setActiveTestimonial(index)}
                   aria-label={`Go to testimonial ${index + 1}`}
                 />
               ))}
             </div>

             {/* Next Button */}
             <Button
               variant="secondary"
               size="icon"
               className="rounded-full p-1.5 h-auto w-auto" // Adjust size/padding
               onClick={handleNext}
               aria-label="Next testimonial"
             >
               <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                 <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
               </svg>
             </Button>
          </div>
        </div> {/* Closing tag for the relative container */}
      </div> {/* Add missing closing tag for the max-w-7xl container */}
    </section>
    </LazyMotion>
  )
}
